import {
  CardContent,
  makeStyles,
  Typography,
  TableCell,
  TableBody,
  Card,
  TableHead,
  TableRow,
  TableContainer,
  Table,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import MLG_LOGO from "../../../assets/images/MLG_LOGO1.png";
import axios from "axios";

const useStyles = makeStyles({
  tblContent: {
    maxWidth: 1100,
    margin: "0 auto",
    marginTop: 12,
    fontSize: 16,
  },
  tblCell: {
    fontWeight: "bold",
  },
  tblCellData: {
    padding: "0 0 0 15px",
  },
  mlgLogo: {
    eight: 90,
    width: 90,
    margin: "0 auto",
  },
  mlgHeader: {
    fontSize: 18,
    textAlign: "center",
    fontWeight: "bold",
  },
  mlgText: {
    fontSize: 12,
    textAlign: "center",
    fontWeight: "bolder",
  },
  center_logo: {
    textAlign: "center",
  },
  eventName: {
    marginTop: 18,
    fontSize: 30,
    fontWeight: "bold",
    textAlign: "center",
  },
  badgeDanger: {
    backgroundColor: "#D32F2F",
    color: "#fff",
    padding: "2px 10px",
  },
});

const API = process.env.REACT_APP_API_URL;
const currentURL = window.location.href;
const eventId = currentURL.split("/");

function PrintEvent() {
  const classes = useStyles();
  const [eventAttendees, setEventAttendees] = useState({});
  const [eventDetails, setEventDetails] = useState(false);

  useEffect(() => {
    fetchData();
    setInterval(() => {
      window.print();
      window.close();
    }, 500);
  }, []);

  const fetchData = () => {
    const token = localStorage.getItem("accessToken");

    axios
      .get(`${API}/print-event-attendance/${eventId[4]}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setEventAttendees(res.data);
          setEventDetails(res.data.event);
        }
      });
  };
  return (
    <Fragment>
      <CardContent>
        <div className={classes.center_logo}>
          <img src={MLG_LOGO} alt="MLG LOGO" className={classes.mlgLogo} />
        </div>
        <Typography className={classes.mlgHeader}>
          MLG Collge of Learning Inc.
        </Typography>
        <Typography className={classes.mlgText}>
          Brgy. Atabay Hilongos, Leyte
        </Typography>
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          className="text-center text-uppercase mt-3 mb-0"
        >
          {eventDetails.event_name}
        </Typography>
        <Typography
          variant="subtitle1"
          display="block"
          gutterBottom
          component="div"
          className="text-center"
        >
          {eventDetails.event_date}
        </Typography>
      </CardContent>
      <Card className={classes.tblContent}>
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tblCell}>School Id</TableCell>
                  <TableCell className={classes.tblCell}>First Name</TableCell>
                  <TableCell className={classes.tblCell}>Last Name</TableCell>
                  <TableCell className={classes.tblCell}>Middle Name</TableCell>
                  <TableCell className={classes.tblCell}>Time In</TableCell>
                  <TableCell className={classes.tblCell}>Time Out</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventAttendees.data?.map((attendees, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell className={classes.tblCellData}>
                        {attendees.schoolId}
                      </TableCell>
                      <TableCell className={classes.tblCellData}>
                        {attendees.firstName}
                      </TableCell>
                      <TableCell className={classes.tblCellData}>
                        {attendees.lastName}
                      </TableCell>
                      <TableCell className={classes.tblCellData}>
                        {attendees.middleName}
                      </TableCell>
                      <TableCell className={classes.tblCellData}>
                        {attendees.timeIn === "" ? (
                          <span className={classes.badgeDanger}>
                            No Time In
                          </span>
                        ) : (
                          attendees.timeIn
                        )}
                      </TableCell>
                      <TableCell className={classes.tblCellData}>
                        {attendees.timeOut === "" ? (
                          <span className={classes.badgeDanger}>
                            No Time Out
                          </span>
                        ) : (
                          attendees.timeOut
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Fragment>
  );
}

export default PrintEvent;
