import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import Webcam from "react-webcam";
import { b64toBlob } from "../modules/utils/helper";

const useStyles = makeStyles({
  content: {
    width: "100%",
    maxWidth: 350,
  },
  buttonBox: {
    textAlign: "center",
  },
});
function WebCamera({ handleCapture }) {
  const classes = useStyles();
  const videoConstraints = {
    width: 350,
    height: 350,
    facingMode: "user",
  };

  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({
      width: 350,
      height: 350,
    });

    if (imageSrc) {
      var block = imageSrc.split(";");
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      var blob = b64toBlob(realData, contentType);

      handleCapture(imageSrc, blob);
    }
  }, [webcamRef, handleCapture]);

  return (
    <div className={classes.content}>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width="100%"
        videoConstraints={videoConstraints}
      />
      <div className={classes.buttonBox}>
        <Button variant="contained" color="primary" onClick={capture}>
          Capture
        </Button>
      </div>
    </div>
  );
}

export default WebCamera;
