import Dashboard from "../modules/dashboard/pages/Dashboard";
import Login from "../modules/login/pages/Login";
import EditEvent from "../modules/event/pages/EditEvent";
import User from "../modules/user/User";
import Scanner from "../modules/scanner-attendance/pages/Scanner";
import Register from "../modules/user/Register";
import EventsReport from "../modules/report/pages/EventsReport";
import PrintEvent from "../modules/report/pages/PrintEvent";
import Profile from "../modules/profile/Profile";
import GlobalSettings from "../modules/settings/pages/GlobalSettings";

const routes = [
  {
    path: "/",
    component: Login,
    auth: false,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    auth: true,
  },

  {
    path: "/users",
    component: User,
    auth: true,
  },

  {
    path: "/scanner/:type/:id",
    component: Scanner,
    auth: true,
  },

  {
    path: "/editevent",
    component: EditEvent,
    auth: true,
  },
  {
    path: "/print-event-attendance/:id",
    component: PrintEvent,
  },
  {
    path: "/register/:id",
    component: Register,
  },
  {
    path: "/report",
    component: EventsReport,
  },
  {
    path: "/profile",
    component: Profile,
    auth: true,
  },
  {
    path: "/settings",
    component: GlobalSettings,
    auth: true,
  },
];

export default routes;
