import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import AlertMessageModal from "../../../components/AlertMessageModal";
import StudentInfo from "../component/StudentInfo";
import TimeLogModal from "../component/TimeLogModal";
import QrReader from "react-qr-scanner";

const useStyles = makeStyles({
  content: {
    maxWidth: 1100,
    margin: "0 auto",
  },
  qrcode: {
    width: "100%",
    maxWidth: 450,
    height: 450,
    padding: "6px 12px",
    margin: "0 auto",
  },
  scanner: {
    textAlign: "center",
    width: "100%",
  },
  header: {
    marginTop: 40,
  },
  headerText: {
    fontWeight: "bold",
    fontSize: "1.6em",
  },
  loading: {
    marginTop: 12,
  },
});

const API = process.env.REACT_APP_API_URL;
function ScannerIN({ values = {} }) {
  const classes = useStyles();
  const [showScanner, setShowScanner] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState({
    message: "",
    type: "initial",
    open: false,
  });
  const [studentData, setStudentData] = useState({});
  const [showStudentInfo, setShowStudentInfo] = useState(false);
  const [showTimeLogModal, setShowTimeLogModal] = useState(false);
  const token = localStorage.getItem("accessToken");

  const handleScan = (data) => {
    if (data && data.text) {
      const qrCode = data.text;
      const qrCodeArray = qrCode.split("/");
      const qrId = qrCodeArray[qrCodeArray.length - 1];
      if (qrId && qrCode.includes("portal")) {
        handleGetQrData(qrId);
      } else {
        setShowMessage((prev) => ({
          ...prev,
          message: "Invalid Code",
          open: true,
          type: "error",
        }));
      }
    }
  };

  const handleGetQrData = (id) => {
    setLoading(true);
    axios
      .get(`https://api-portal.mlgcl.edu.ph/api/validate-qrcode/${id}`)
      .then((res) => {
        if (res.data.data) {
          setStudentData(res.data.data);
          setShowStudentInfo(true);
          setLoading(false);
          setShowScanner(false);
          // checkIfAttendanceExists(res.data.data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const checkIfAttendanceExists = (data) => {
    console.log(token);
    const params = {
      event_id: values.id,
      participant_id: studentData.student_id,
      time_type: "IN",
    };

    const formData = new FormData();
    for (const key in params) {
      formData.append(key, params[key]);
    }

    axios
      .get(`${API}/attendance/checkifExists/${data.student_id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
      });
  };

  const handleClockIn = () => {
    setShowTimeLogModal(true);
  };

  const handleSubmit = (image) => {
    const params = {
      event_id: values.id,
      first_name: studentData.first_name,
      last_name: studentData.last_name,
      middle_name: studentData.middle_name,
      participant_id: studentData.student_id,
      contact_number: studentData.emergency_contact_number,
      email: studentData.email,
      time_type: "IN",
      image,
    };

    const formData = new FormData();
    for (const key in params) {
      formData.append(key, params[key]);
    }

    axios
      .post(`${API}/attendance`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // check if success
        if (res.data.code === 200) {
          setShowTimeLogModal(false);
          handleCancelTimeLog();
          setStudentData({});
          setShowStudentInfo(false);
          setShowScanner(true);
          setShowMessage({
            message: "Welcome! You have successfully CLOCKED IN",
            open: true,
            type: "initial",
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setShowMessage({
            message: err.response && err.response.data.message,
            open: true,
            type: "error",
          });
          handleCancelTimeLog();
        }
      });
  };

  const handleCancel = () => {
    setStudentData({});
    setShowStudentInfo(false);
    setShowScanner(true);
  };

  const handleCancelTimeLog = () => {
    setShowTimeLogModal(false);
  };

  return (
    <div>
      <div className={classes.content}>
        <div className={classes.header}>
          <Typography
            className={classes.headerText}
            align="center"
            variant="h6"
          >
            Event Name: {values.event_name}
          </Typography>
          <Typography align="center">
            Event Date: {values.event_date}
          </Typography>
        </div>
        <div className={classes.scanner}>
          {loading ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : (
            <div>
              {showScanner === true && (
                <div className={classes.qrcode}>
                  <QrReader
                    onScan={handleScan}
                    onError={(err) => console.log(err)}
                    style={{ width: "100%", height: 450 }}
                  />
                </div>
              )}
            </div>
          )}

          {showStudentInfo && (
            <StudentInfo
              handleClockIn={handleClockIn}
              handleCancel={handleCancel}
              buttonText="CLOCK IN"
              values={studentData}
            />
          )}
        </div>
      </div>

      <TimeLogModal
        onClose={handleCancelTimeLog}
        open={showTimeLogModal}
        handleProceed={handleSubmit}
      />

      <AlertMessageModal
        message={showMessage.message}
        open={showMessage.open}
        onClose={() => setShowMessage((prev) => ({ ...prev, open: false }))}
        type={showMessage.type}
      />
    </div>
  );
}

export default ScannerIN;
