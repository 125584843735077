import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import axios from "axios";
import { useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import {
  FormGroup,
  FormControlLabel,
  makeStyles,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles({});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const API = process.env.REACT_APP_API_URL;
export default function NewEvent({ open, handleClose, refetch }) {
  const classes = useStyles();
  const [allowSMS, setAllowSMS] = useState(false);
  const [userList, setUserList] = useState({
    code: "",
    users: [],
  });

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(`${API}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          const users = res.data.users.map((user) => {
            const userArray = {};

            userArray["value"] = user.id;
            userArray["label"] = `${user.first_name} ${user.last_name}`;

            return userArray;
          });
          setUserList(users);
          const allowSMS = res.data.sms == "1" ? true : false;
          setAllowSMS(allowSMS);
        }
      });
  };
  const [formValues, setFormValues] = useState({
    event_name: "",
    event_date: "",
    time_in_starts: "",
    time_in_ends: "",
    time_out_starts: "",
    time_out_ends: "",
    allow_sms: "",
  });

  const [state] = React.useState({
    on: true,
    off: false,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormValues((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handlesubmit = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .post(`${API}/events`, formValues, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          refetch();
          handleClose();
        }
      });
  };
  return (
    <div>
      <Dialog
        className={classes.root}
        open={open}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="sm"
        keepMounted
      >
        <DialogTitle id="form-dialog-title">New Event</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContentText style={{ marginBottom: 0 }}>
            Please enter your New Event here.
          </DialogContentText>
          <div style={{ marginBottom: 10 }}>
            <TextField
              autoFocus
              margin="dense"
              id="outlined-secondary"
              name="event_name"
              label="Event Name"
              color="primary"
              type="input"
              fullWidth
              value={formValues.event_name}
              onChange={handleChange}
            />
          </div>
          <div style={{ marginBottom: 15 }}>
            <TextField
              id="date"
              name="event_date"
              label="Event Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={formValues.event_date}
              onChange={handleChange}
            />
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Time"
                name="time_in_starts"
                label=" Time IN Starts"
                type="time"
                helperText="This is the time the system STARTS accepting TIME IN request."
                InputLabelProps={{
                  shrink: true,
                }}
                value={formValues.time_in_starts}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="time"
                name="time_in_ends"
                label="Time IN Ends"
                type="time"
                helperText="This is the time the system STOPS accepting TIME OUT request."
                InputLabelProps={{
                  shrink: true,
                }}
                value={formValues.time_in_ends}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Time"
                name="time_out_starts"
                label=" Time OUT Starts"
                type="time"
                helperText="This is the time the system STARTS accepting TIME OUT request."
                InputLabelProps={{
                  shrink: true,
                }}
                value={formValues.time_out_starts}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Time"
                name="time_out_ends"
                label=" Time OUT Ends"
                type="time"
                helperText="This is the time the system STOPS accepting TIME OUT request."
                InputLabelProps={{
                  shrink: true,
                }}
                value={formValues.time_out_ends}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          {allowSMS && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.allow_sms}
                    onChange={handleChange}
                    name="allow_sms"
                  />
                }
                label="SMS Notification"
                value={formValues.allow_sms === 0 ? "On" : "Off"}
                onChange={handleChange}
              />
            </FormGroup>
          )}
        </DialogContent>
        {/* <Select options={userList} isMulti /> */}
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handlesubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
