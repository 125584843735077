import {
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddUser from "./component/AddUser";
import DeleteUsers from "./component/DeleteUsers";
import EditUser from "./component/EditUser";
import axios from "axios";

const useStyles = makeStyles({
  content: {
    maxWidth: 1100,
    margin: "0 auto",
    marginTop: 12,
  },
  button: {
    marginTop: "20px",
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: "100%",
  },
});

const API = process.env.REACT_APP_API_URL;

function User() {
  const classes = useStyles();
  const [selectedUserValues, setselectedUserValues] = useState({});
  const [openAddUser, setOpenAddUser] = useState(false);
  const [selectedID, setSelectedID] = useState("");
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [userList, setUserList] = useState({
    code: "",
    users: [],
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const token = localStorage.getItem("accessToken");

    axios
      .get(`${API}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setUserList(res.data);
        }
      });
  };

  const handleShowAddModal = () => {
    setOpenAddUser(true);
  };

  const handleOpenDeleteUser = (userID) => {
    setSelectedID(userID);
    setOpenDeleteUser(true);
  };

  const handleConfirmDelete = () => {
    const token = localStorage.getItem("accessToken");

    axios
      .delete(`${API}/user/${selectedID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.delete) {
          setOpenDeleteUser(false);
          fetchData();
        }
      });
  };
  console.log(handleConfirmDelete);

  const handleOpenEditUser = (userValues) => {
    setselectedUserValues(userValues);
    setOpenEditUser(true);
  };
  console.log(userList);
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Card>
          <CardContent>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Profile Pic</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Middle Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList.users.map((users, key) => (
                    <TableRow key={key}>
                      <TableCell>{key + 1}</TableCell>
                      <TableCell>
                        {users.profile_pic ? (
                          <img
                            className={classes.image}
                            alt="profile-image"
                            src={users.profile_pic}
                          />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell>{users.last_name}</TableCell>
                      <TableCell>{users.first_name}</TableCell>
                      <TableCell>{users.middle_name}</TableCell>
                      <TableCell>
                        {users.status == 0 ? "Pending" : "Active"}
                      </TableCell>
                      <TableCell>{users.email}</TableCell>
                      <TableCell>
                        <IconButton
                          title="Edit"
                          onClick={() => handleOpenEditUser(users)}
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                        <IconButton
                          title="Delete"
                          onClick={() => handleOpenDeleteUser(users.id)}
                        >
                          <DeleteIcon color="secondary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleShowAddModal}
        >
          <AddIcon />
          Add New User
        </Button>
        <AddUser
          open={openAddUser}
          handleClose={() => setOpenAddUser(false)}
          refetch={fetchData}
        />
      </div>
      <DeleteUsers
        open={openDeleteUser}
        handleConfirmDelete={handleConfirmDelete}
        handleClose={() => setOpenDeleteUser(false)}
      />
      <EditUser
        selectedUserValues={selectedUserValues}
        open={openEditUser}
        handleClose={() => setOpenEditUser(false)}
        refetch={fetchData}
      />
    </div>
  );
}

export default User;
