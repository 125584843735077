import { LinearProgress } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ScannerIN from "./ScannerIN";
import ScannerOUT from "./ScannerOUT";

const API = process.env.REACT_APP_API_URL;
function Scanner({ match }) {
  const { params } = match;

  const [loading, setLoading] = useState(false);
  const [eventsValues, setEventsValue] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (params.id) {
      setLoading(true);
      axios
        .get(`${API}/event/scan/${params.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.code === 200) {
            setEventsValue(res.data.list);
          }
          setLoading(false);
        });
    }
  }, [params.id]);

  return loading ? (
    <LinearProgress />
  ) : (
    <div>
      {params.type === "in" && <ScannerIN values={eventsValues} />}
      {params.type === "out" && <ScannerOUT values={eventsValues} />}
    </div>
  );
}

export default Scanner;
