import axios from "axios";
import React, { useState } from "react";
import MLG_LOGO from "../../../assets/images/MLG_LOGO1.png";
import "../../../assets/css/login/Login.css";
import { isAuth } from "../../utils/helper";
import { Redirect } from "react-router-dom";

const API = process.env.REACT_APP_API_URL;
function Login() {
  const [formValues, setFormValues] = useState({
    username: "",
    password: "",
  });

  if (isAuth()) {
    return <Redirect to="/dashboard" />;
  }

  const handleSubmit = () => {
    axios
      .post(`${API}/login`, {
        email: formValues.username,
        password: formValues.password,
      })
      .then((res) => {
        if (res.data.code === 200) {
          localStorage.setItem("accessToken", res.data.token);
          window.location.href = "/dashboard";
        }
      })
      .catch((err) => {
        alert("Invalid Username and Password");
        // console.log(err.response.data);
      });
  };

  const handleInputChange = (name, value) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="container">
      <div className="login--center">
        <img src={MLG_LOGO} alt="MLG LOGO" className="mlg--logo" />
        <div>
          <h1 className="mlg--Heading">MLG College of Learning Inc.</h1>
        </div>
        <h3 className="mlg--subHeading">QR Based Attendance Tracking System</h3>
        <div className="form-group">
          <label htmlFor="email">Email address:</label>
          <input
            type="email"
            className="form-control mt-2 mb-1"
            placeholder="Enter email"
            id="email"
            value={formValues.username}
            onChange={(e) => handleInputChange("username", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="pwd">Password:</label>
          <input
            type="password"
            className="form-control mt-2"
            placeholder="Enter password"
            id="pwd"
            value={formValues.password}
            onChange={(e) => handleInputChange("password", e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary col-12 mt-3"
          onClick={handleSubmit}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
}

export default Login;
