import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import "../assets/css/dashboard/dashboard.css";
import AlertLogout from "../../src/modules/event/pages/AlertLogout";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  item: {
    color: "black",
    textDecoration: "none",
  },
  noPrint: {
    ["@media print"]: {
      display: "none",
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openLogout, setOpenLogout] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenLogout = () => {
    setOpenLogout(true);
  };
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  return (
    <div className={`${classes.root} ${classes.noPrint}`}>
      <AppBar position="static">
        <Toolbar>
          <Typography id="dashboard" variant="h6" className={classes.title}>
            QR Base Attendance Tracking System
          </Typography>
          <div>
            <IconButton
              aria-label="account of current user"
              id="icon"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem>
                <span>
                  <a href={`${BASE_URL}/dashboard`} className={classes.item}>
                    Dashboard
                  </a>
                </span>
              </MenuItem>
              <MenuItem>
                <a href={`${BASE_URL}/profile`} className={classes.item}>
                  Profile
                </a>
              </MenuItem>
              <MenuItem>
                <a href={`${BASE_URL}/settings`} className={classes.item}>
                  Settings
                </a>
              </MenuItem>
              <MenuItem>
                <a href={`${BASE_URL}/users`} className={classes.item}>
                  Users
                </a>
              </MenuItem>
              <MenuItem onClick={handleOpenLogout}>Logout</MenuItem>
            </Menu>
          </div>
          <AlertLogout
            open={openLogout}
            handleClose={() => setOpenLogout(false)}
          />
        </Toolbar>
      </AppBar>
    </div>
  );
}
