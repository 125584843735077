import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PrintPerIndividualEvent({
  open,
  handleClose,
  handleOpenPrint,
}) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Choose Time Type"}
      </DialogTitle>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleOpenPrint}>
          Clock In
        </Button>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Clock Out
        </Button>
      </DialogActions>
    </Dialog>
  );
}
