import { Card, CardContent, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PrintIcon from "@material-ui/icons/Print";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteEvent from "../../event/pages/DeleteEvent";
import ViewModal from "../../event/pages/ViewEvent";
import EditEvent from "../../event/pages/EditEvent";
import NewEvent from "../../event/pages/NewEvent";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import "../../../assets/css/dashboard/dashboard.css";
import PrintPerIndividualEvent from "../../event/pages/PrintPerIndividualEvent";
import MLG_LOGO from "../../../assets/images/MLG_LOGO1.png";
import { Typography } from "@mui/material";

const events = [
  {
    value: "todays_event",
    label: "Today's Events",
  },
  {
    value: "upcoming_event",
    label: "Upcoming Events",
  },
  {
    value: "past_event",
    label: "Past Events",
  },
];

const useStyles = makeStyles({
  content: {
    maxWidth: 1100,
    margin: "0 auto",
    marginTop: 12,
  },
  button: {
    marginRight: "20px",
  },
  buttonbox: {
    marginTop: "20px",
  },
  cell: {
    fontWeight: "bold",
  },
  root: {
    margin: 1,
    width: "10 ch",
  },
  noPadding: {
    padding: "0 0 0 15px",
  },
  mlgLogo: {
    eight: 90,
    width: 90,
    margin: "0 auto",
  },
  mlgHeader: {
    fontSize: 18,
    textAlign: "center",
    fontWeight: "bold",
  },
  mlgText: {
    fontSize: 12,
    textAlign: "center",
    fontWeight: "bolder",
  },
  center_logo: {
    textAlign: "center",
  },
  noPrint: {
    ["@media print"]: {
      display: "none",
    },
  },
  displayNone: {
    display: "none",
  },
  displayPrint: {
    ["@media print"]: {
      display: "block",
    },
  },
});

const API = process.env.REACT_APP_API_URL;
function Dashboard() {
  const classes = useStyles();
  const [openView, setOpenView] = useState(false);
  const [selectedID, setSelectedID] = useState("");
  const [selectedEventValues, setselectedEventValues] = useState({});
  const [openPrint, setOpenPrint] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filters, setFilters] = React.useState({
    date_from: "",
    date_to: "",
    event_filter: "",
  });
  const [disableTimeInBtn, setDisableTimeInBtn] = useState(false);
  const [disableTimeOutBtn, setDisableTimeOutBtn] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const rows = [];
  const [eventList, setEventList] = useState({
    code: "",
    list: [],
    sms: false,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (params = {}) => {
    const token = localStorage.getItem("accessToken");

    axios
      .get(`${API}/events`, {
        params: { ...filters, ...params },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setEventList(res.data);
        }
      });
  };

  const handleOpenView = (eventValues) => {
    const convertedTimeInStart = timeConvert(eventValues.time_in_starts);
    const convertedTimeInEnd = timeConvert(eventValues.time_in_ends);
    const convertedTimeOutStart = timeConvert(eventValues.time_out_starts);
    const convertedTimeOutEnd = timeConvert(eventValues.time_out_ends);

    const combinedEventValues = {
      ...eventValues,
      convertedTimeInStart,
      convertedTimeInEnd,
      convertedTimeOutStart,
      convertedTimeOutEnd,
    };
    setselectedEventValues(combinedEventValues);
    checkTimeTreshold(eventValues.id);
    setOpenView(true);
  };

  const checkTimeTreshold = (id) => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(`${API}/attendance/treshold/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setDisableTimeInBtn(res.data.timeIn);
          setDisableTimeOutBtn(res.data.timeOut);
        }
      });
  };

  const handleOpenPrint = () => {
    setOpenPrint(true);
  };

  const handleOpenDelete = (eventID) => {
    setSelectedID(eventID);
    setOpenDelete(true);
  };

  const handleConfirmDelete = () => {
    const token = localStorage.getItem("accessToken");

    axios
      .delete(`${API}/event/${selectedID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.delete) {
          setOpenDelete(false);
          fetchData();
        }
      });
  };

  const handleOpenEdit = (eventValues, sms) => {
    const allowSMS = {
      globalAllowSMS: sms,
    };
    const combinedEventValues = Object.assign(eventValues, allowSMS);
    setselectedEventValues(combinedEventValues);
    setOpenEdit(true);
  };

  const timeConvert = (time) => {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  };

  const handleOpenNew = () => {
    setOpenNew(true);
  };

  const handleFilterChange = (name, value) => {
    if (name === "event_filter") {
      fetchData({
        event_filter: value,
        date_from: "",
        date_to: "",
      });
    } else {
      fetchData({ [name]: value });
    }

    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFilters((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleFilterSubmit = (filter) => {
    handleFilterChange(true);
  };

  return (
    <div>
      <CardContent className={`${classes.displayNone} ${classes.displayPrint}`}>
        <div className={classes.center_logo}>
          <img src={MLG_LOGO} alt="MLG LOGO" className={classes.mlgLogo} />
        </div>
        <Typography className={classes.mlgHeader}>
          MLG Collge of Learning Inc.
        </Typography>
        <Typography className={classes.mlgText}>
          Brgy. Atabay Hilongos, Leyte
        </Typography>
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          className="text-center text-uppercase mt-3 mb-0"
        >
          List of Events
        </Typography>
      </CardContent>
      <div className={classes.content}>
        <Card>
          <CardContent className={classes.noPrint}>
            <div id="head">
              <div id="topEventsSelect">
                <TextField
                  select
                  label="Events"
                  value={filters.event_filter}
                  onChange={(e) =>
                    handleFilterChange("event_filter", e.target.value)
                  }
                  SelectProps={{
                    native: true,
                  }}
                  className={classes.noPrint}
                >
                  {events.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div>
              <TextField
                id="date"
                type="date"
                name="date_to"
                margin="dense"
                variant="outlined"
                value={filters.date_to}
                onChange={handleChange}
                className={classes.noPrint}
              />
              <p id="para" className={classes.noPrint}>
                To
              </p>
              <TextField
                type="date"
                margin="dense"
                name="date_from"
                variant="outlined"
                value={filters.date_from}
                onChange={handleChange}
                className={classes.noPrint}
              />
              <Button
                id="button"
                className={`${classes.button} ${classes.noPrint}`}
                variant="contained"
                size="small"
                color="primary"
                onClick={handleFilterSubmit}
              >
                Filter
              </Button>
            </div>
          </CardContent>
          <CardContent>
            <TableContainer>
              <Table size="medium" spacing={2}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={`${classes.cell} ${classes.noPadding}`}
                    >
                      Event Name
                    </TableCell>
                    <TableCell
                      className={`${classes.cell} ${classes.noPadding}`}
                    >
                      Event Date
                    </TableCell>
                    <TableCell
                      className={`${classes.cell} ${classes.noPrint} ${classes.noPadding}`}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.noPadding}>
                  {eventList.list.map((events, key) => {
                    return (
                      <TableRow key={key}>
                        <TableCell
                          id="event"
                          onClick={() => handleOpenView(events)}
                          className={classes.noPadding}
                        >
                          {events.event_name}
                        </TableCell>
                        <TableCell className={classes.noPadding}>
                          {events.event_date}
                        </TableCell>
                        <TableCell
                          className={`${classes.noPadding} ${classes.noPrint}`}
                        >
                          <IconButton
                            title="Edit"
                            onClick={() =>
                              handleOpenEdit(events, eventList.sms)
                            }
                          >
                            <EditIcon color="primary" />
                          </IconButton>
                          <IconButton
                            title="Delete"
                            onClick={() => handleOpenDelete(events.id)}
                            className="p-0"
                          >
                            <DeleteIcon color="secondary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className={classes.noPrint}
              />
            </TableContainer>
          </CardContent>
        </Card>
        <div className={classes.buttonbox}>
          <Button
            id="btn-1"
            className={`${classes.button} ${classes.noPrint}`}
            variant="contained"
            color="primary"
            onClick={handleOpenNew}
          >
            <AddIcon />
            Add New Event
          </Button>
          <Button
            onClick={() => {
              window.print();
            }}
            variant="contained"
            color="secondary"
            id="btn-2"
            className={classes.noPrint}
          >
            <PrintIcon />
            Print Event List
          </Button>
        </div>
        <DeleteEvent
          handleConfirmDelete={handleConfirmDelete}
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
        />
        <ViewModal
          selectedEventValues={selectedEventValues}
          open={openView}
          handleClose={() => setOpenView(false)}
          disableTimeInBtn={disableTimeInBtn}
          disableTimeOutBtn={disableTimeOutBtn}
        />
        <EditEvent
          selectedEventValues={selectedEventValues}
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          refetch={fetchData}
        />
        <NewEvent
          open={openNew}
          handleClose={() => setOpenNew(false)}
          refetch={fetchData}
        />
        <PrintPerIndividualEvent
          handleOpenPrint={handleOpenPrint}
          handleClose={() => setOpenPrint(false)}
          open={openPrint}
        />
      </div>
    </div>
  );
}

export default Dashboard;
