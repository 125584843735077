import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import { Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import InviteConfirm from "./InviteConfirm";

export default function FormDialog({ open, handleClose, refetch }) {
  const [showLoading, setShowLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);
  const [openInviteConfirm, setOpenInviteConfirm] = useState(false);
  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const handleClickInvite = () => {
    if (email !== "") {
      setShowLoading(true);
      let rootUrl = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem("accessToken");
      axios
        .post(
          rootUrl + `/user-invite`,
          { email },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.code === 200) {
            setShowLoading(false);
            setOpenInviteConfirm(true);
          }
        });
    } else setShowError(true);
  };
  const handleCLoseInviteConfirm = () => {
    refetch();
    setOpenInviteConfirm(false);
    handleClose();
  };
  console.log(email);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {showLoading && <LinearProgress color="primary" />}
        <DialogTitle id="form-dialog-title">Invitation Link</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            value={email}
            onChange={handleChange}
            fullWidth
          />
          {showError && (
            <Typography color="error">Email field cannot be empty</Typography>
          )}
          <InviteConfirm
            open={openInviteConfirm}
            handleClose={() => handleCLoseInviteConfirm()}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleClickInvite} color="primary">
            Send invite
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
