import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { IconButton, TableCell } from "@material-ui/core";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Profile from "../../../assets/images/Profile.jpg";
import axios from "axios";

const useStyles = makeStyles({
  img_holder: {
    textAlign: "center",
  },
  photo_label: {
    variant: "h6",
    fontSize: 14,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const API = process.env.REACT_APP_API_URL;
export default function EditUser({
  open,
  handleClose,
  selectedUserValues,
  refetch,
}) {
  const classes = useStyles();
  const [imagePreview, setImagePreview, setOpenEditUser] = useState("");
  const [formValues, setFormValues] = useState({
    profile_pic: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    status: "",
    email: "",
  });

  useEffect(() => {
    if (open) {
      setImagePreview("");
      if (selectedUserValues.profile_pic) {
        setImagePreview(selectedUserValues.profile_pic);
      }
      setFormValues((prev) => ({
        ...prev,
        profile_pic: selectedUserValues.profile_pic,
        first_name: selectedUserValues.first_name,
        last_name: selectedUserValues.last_name,
        middle_name: selectedUserValues.middle_name,
        status: selectedUserValues.status,
        email: selectedUserValues.email,
      }));
    }
  }, [open, selectedUserValues]);

  const handleChange = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const Input = styled("input")({
    display: "none",
  });

  const handleSubmit = () => {
    const token = localStorage.getItem("accessToken");

    const formData = new FormData();

    for (const key in formValues) {
      formData.append(key, formValues[key]);
    }

    axios
      .post(`${API}/user/${selectedUserValues.id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          refetch();
          handleClose(false);
          setOpenEditUser(false);
        }
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setImagePreview(URL.createObjectURL(file));
    } else {
      setImagePreview("");
    }

    setFormValues((prev) => ({
      ...prev,
      profile_pic: file,
    }));
  };

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
        <div>
          <img
            className={classes.profile_image}
            src={imagePreview ? imagePreview : Profile}
            alt="PROFILE"
            id="user_profile"
          />
        </div>

        <TableCell>
          <div className={classes.cam_holder}>
            <label htmlFor="icon-button-file">
              <Input
                accept="img/*"
                id="icon-button-file"
                type="file"
                onChange={handleFileChange}
              />
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <AddAPhotoIcon />
              </IconButton>
            </label>
          </div>
        </TableCell>
        <DialogContent>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="outlined-multiline-left"
              label="First Name"
              multiline
              maxRows={4}
              variant="standard"
              color="secondary"
              type="input"
              fullWidth
              onChange={(e) => handleChange("first_name", e.target.value)}
              value={formValues.first_name || ""}
            />
          </DialogContent>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="outlined-multiline-flexible"
              label="Last Name"
              multiline
              variant="standard"
              color="secondary"
              type="input"
              fullWidth
              onChange={(e) => handleChange("last_name", e.target.value)}
              value={formValues.last_name || ""}
            />
          </DialogContent>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="outlined-multiline-flexible"
              label="Middle Name"
              multiline
              variant="standard"
              color="secondary"
              type="input"
              fullWidth
              onChange={(e) => handleChange("middle_name", e.target.value)}
              value={formValues.middle_name || ""}
            />
          </DialogContent>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="outlined-multiline-flexible"
              label="Status"
              multiline
              variant="standard"
              color="secondary"
              type="input"
              fullWidth
              onChange={(e) => handleChange("status", e.target.value)}
              value={formValues.status || ""}
            />
          </DialogContent>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="outlined-multiline-flexible"
              label="Email"
              multiline
              variant="standard"
              color="secondary"
              type="input"
              fullWidth
              onChange={(e) => handleChange("email", e.target.value)}
              value={formValues.email || ""}
            />
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Update
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
