import { Button, CardMedia, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import profile from "../../../assets/images/no_image.png";

const useStyles = makeStyles({
  image: {
    width: "100%",
    maxWidth: 200,
    height: 200,
    margin: "0 auto",
    borderRadius: 100,
  },
  imageBox: {
    padding: "16px 12px",
  },
});
function StudentInfo({ values, handleClockIn, handleCancel, buttonText }) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.imageBox}>
        {values && values.image ? (
          <CardMedia image={values.image} className={classes.image} />
        ) : (
          <CardMedia image={profile} className={classes.image} />
        )}
      </div>
      <div>
        <Typography variant="h6">
          {(values && values.student_id) || "Dummy Student ID"}
        </Typography>
        <Typography>
          {(values && values.full_name) || "Dummy Full Name"}
        </Typography>
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClockIn}
          style={{ marginRight: 8 }}
        >
          {buttonText}
        </Button>

        <Button variant="contained" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default StudentInfo;
