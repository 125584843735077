import {
  Card,
  CardContent,
  makeStyles,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { SettingsInputAntennaTwoTone } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import axios from "axios";

const usestyles = makeStyles({
  root: {
    width: "35%",
    marginTop: 30,
    margin: "0 auto",
  },
  title: {
    backgroundColor: "#4e73df",
    color: "#fff",
    padding: 14,
    borderRadius: 5,
  },
});

function GlobalSettings() {
  const classes = usestyles();
  const [activate, setActivate] = React.useState({
    global_sms: false,
  });

  const API = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(`${API}/settings/global_sms`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          const status = res.data.data.value == "1" ? true : false;

          setActivate({
            ...activate,
            global_sms: status,
          });
        }
      });
    console.log(activate);
  };

  const handleChange = (event) => {
    setActivate({
      ...activate,
      [event.target.name]: event.target.checked,
    });
    saveConfig();
  };

  const saveConfig = () => {
    axios
      .put(`${API}/settings/update/global_sms`, activate, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          console.log(res.data);
        }
      });
  };
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title}>Settings</Typography>
      </CardContent>
      <CardContent>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={activate.global_sms}
                onChange={handleChange}
                color="primary"
                name="global_sms"
              />
            }
            label="Activate SMS Notification Feature Globally"
          />
        </FormGroup>
      </CardContent>
    </Card>
  );
}

export default GlobalSettings;
