import {
  Card,
  CardContent,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import MLG_LOGO from "../../../assets/images/MLG_LOGO1.png";

const useStyles = makeStyles({
  tblContent: {
    maxWidth: 1100,
    margin: "0 auto",
    marginTop: 12,
  },
  tblCell: {
    fontWeight: "bold",
  },
  tblCellData: {
    padding: 2,
  },
  mlgHeader: {
    fontSize: 18,
    textAlign: "center",
    fontWeight: "bold",
  },
  mlgText: {
    fontSize: 12,
    textAlign: "center",
    fontWeight: "bolder",
  },
  mlgLogo: {
    height: 90,
    width: 90,
    margin: "0 auto",
  },
  center_logo: {
    textAlign: "center",
  },
});

const API = process.env.REACT_APP_API_URL;
function EventsReport() {
  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  const [eventList, setEventList] = useState({
    code: "",
    list: [],
  });

  const fetchData = () => {
    const token = localStorage.getItem("accessToken");

    axios
      .get(`${API}/events`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setEventList(res.data);
        }
      });
  };

  return (
    <Fragment>
      <CardContent>
        <div className={classes.center_logo}>
          <img src={MLG_LOGO} alt="MLG LOGO" className={classes.mlgLogo} />
        </div>
        <Typography className={classes.mlgHeader}>
          MLG Collge of Learning Inc.
        </Typography>
        <Typography className={classes.mlgText}>
          Brgy. Atabay Hilongos, Leyte
        </Typography>
      </CardContent>

      <Card className={classes.tblContent}>
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tblCell}>Event Name</TableCell>
                  <TableCell className={classes.tblCell}>Event Date</TableCell>
                  <TableCell className={classes.tblCell}>Event Start</TableCell>
                  <TableCell className={classes.tblCell}>Event End</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventList.list.map((events, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell className={classes.tblCellData}>
                        {events.event_name}
                      </TableCell>
                      <TableCell className={classes.tblCellData}>
                        {events.event_date}
                      </TableCell>
                      <TableCell className={classes.tblCellData}>
                        {events.time_in_starts}
                      </TableCell>
                      <TableCell className={classes.tblCellData}>
                        {events.time_out_ends}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Fragment>
  );
}

export default EventsReport;
