import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import WebCamera from "../../../components/WebCamera";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  btnCapture: {
    marginTop: 8,
    textAlign: "center",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TimeLogModal = ({ open, handleProceed, onClose }) => {
  const classes = useStyles();
  const [imagePreview, setImagePreview] = useState("");
  const [imageBlob, setimageBlob] = useState("");

  useEffect(() => {
    if (open) {
      setImagePreview("");
      setimageBlob("");
    }
  }, [open]);

  const captureCamera = (image, blob) => {
    setImagePreview(image);
    setimageBlob(blob);
  };

  const handleRetake = () => {
    setImagePreview("");
  };

  const handleConfirm = () => {
    handleProceed(imageBlob);
  };

  return (
    <div>
      <Dialog open={open} TransitionComponent={Transition} keepMounted>
        <DialogTitle style={{ textAlign: "center" }}>Time Log</DialogTitle>
        <DialogContent>
          {imagePreview ? (
            <img alt="alt" src={imagePreview} />
          ) : (
            <WebCamera handleCapture={captureCamera} />
          )}

          <div>
            {imagePreview && (
              <div className={classes.btnCapture}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={handleConfirm}
                >
                  Confirm
                </Button>
                <Button
                  style={{ marginLeft: 6 }}
                  variant="contained"
                  color="secondary"
                  size="medium"
                  onClick={handleRetake}
                >
                  Retake
                </Button>
                <Button
                  style={{ marginLeft: 6 }}
                  variant="contained"
                  color="secondary"
                  size="medium"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TimeLogModal;
