import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import "../../assets/css/register/register.css";
import { useState } from "react";
import axios from "axios";
import MLG_LOGO from "../../assets/images/MLG_LOGO1.png";
import { CardContent } from "@material-ui/core";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="/">
        MLGCL-QRBATS
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  mlgLogo: {
    height: 90,
    width: 90,
    margin: "0 auto",
  },
  mlgHeader: {
    fontSize: 18,
    textAlign: "center",
    fontWeight: "bold",
  },
  mlgText: {
    fontSize: 12,
    textAlign: "center",
    fontWeight: "bolder",
  },
  center_logo: {
    textAlign: "center",
  },
}));

const API = process.env.REACT_APP_API_URL;
export default function SignUp({ handleClose, match, fetchData }) {
  const classes = useStyles();

  const [formValues, setFormValues] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    password: "",
    password_confirmation: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormValues((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = () => {
    axios.post(`${API}/register/${match.params.id}`, formValues).then((res) => {
      if (res.data.code === 200) {
        window.location.href = "/dashboard";
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs" id="content">
      <div className={classes.paper}>
        <CardContent>
          <div className={classes.center_logo}>
            <img src={MLG_LOGO} alt="MLG LOGO" className={classes.mlgLogo} />
          </div>
          <Typography className={classes.mlgHeader}>
            MLG Collge of Learning Inc.
          </Typography>
          <Typography className={classes.mlgText}>
            Brgy. Atabay Hilongos, Leyte
          </Typography>
        </CardContent>
        <Typography component="h1" variant="h5">
          User Registration
        </Typography>
        <TextField
          name="first_name"
          margin="dense"
          fullWidth
          id="firstName"
          label="First Name"
          value={formValues.first_name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          id="middleName"
          fullWidth
          label="Middle Name"
          name="middle_name"
          value={formValues.middle_name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          id="lastName"
          fullWidth
          label="Last Name"
          name="last_name"
          value={formValues.last_name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="password"
          fullWidth
          label="Password"
          type="password"
          id="password"
          value={formValues.password}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          fullWidth
          name="password_confirmation"
          label="Confirm Password"
          type="password"
          id="password"
          value={formValues.confirm_password}
          onChange={handleChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Sign Up
        </Button>
        <Grid container justifyContent="flex-end"></Grid>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
