import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CropFreeIcon from "@material-ui/icons/CropFree";
import { Typography } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { useParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ViewEvent({
  open,
  handleClose,
  selectedEventValues,
  disableTimeInBtn,
  disableTimeOutBtn,
}) {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) + :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-slide-title">Event Details</DialogTitle>
        <DialogContent>
          <Divider textAlign="left">{selectedEventValues.event_date}</Divider>
          <Typography variant="h4" gutterBottom component="div">
            {selectedEventValues.event_name}
          </Typography>
          <Divider>Clock In Details</Divider>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={8}>
                <Item>
                  <Typography variant="overline" display="block" gutterBottom>
                    Clock In Starts at:
                  </Typography>
                  <Typography variant="h5" gutterBottom component="div">
                    {selectedEventValues.convertedTimeInStart}
                  </Typography>
                </Item>
              </Grid>
              <Grid item xs={8}>
                <Item>
                  <Typography variant="overline" display="block" gutterBottom>
                    Clock In Ends at:
                  </Typography>
                  <Typography variant="h5" gutterBottom component="div">
                    {selectedEventValues.convertedTimeInEnd}
                  </Typography>
                </Item>
              </Grid>
            </Grid>
          </Box>
          <Divider>Clock Out Details</Divider>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={8}>
                <Item>
                  <Typography variant="overline" display="block" gutterBottom>
                    Clock Out Starts at:
                  </Typography>
                  <Typography variant="h5" gutterBottom component="div">
                    {selectedEventValues.convertedTimeOutStart}
                  </Typography>
                </Item>
              </Grid>
              <Grid item xs={8}>
                <Item>
                  <Typography variant="overline" display="block" gutterBottom>
                    Clock Out Ends at:
                  </Typography>
                  <Typography variant="h5" gutterBottom component="div">
                    {selectedEventValues.convertedTimeOutEnd}
                  </Typography>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "space-between", marginLeft: "15px" }}
        >
          <Button
            disabled={!disableTimeInBtn}
            color="primary"
            variant="contained"
            onClick={() => {
              window.location.href = `/scanner/in/${selectedEventValues.id}`;
            }}
          >
            <CropFreeIcon />
            Scan In
          </Button>
          <Button
            disabled={!disableTimeOutBtn}
            id="scan_out"
            color="secondary"
            variant="contained"
            onClick={() => {
              window.open(`/scanner/out/${selectedEventValues.id}`, "_blank");
            }}
          >
            <CropFreeIcon />
            Scan Out
          </Button>

          <a
            href={`${BASE_URL}/print-event-attendance/${selectedEventValues.id}`}
            target="_blank"
            className="btn btn-warning"
          >
            <PrintIcon />
            &nbsp;Print Attendance
          </a>
          <Button onClick={handleClose} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ViewEvent;
