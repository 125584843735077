import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Switch from "@mui/material/Switch";
import axios from "axios";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Grid } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const API = process.env.REACT_APP_API_URL;
export default function EditEvent({
  open,
  handleClose,
  selectedEventValues,
  refetch,
}) {
  const [formValues, setFormValues] = useState({
    event_name: "",
    event_date: "",
    time_in_starts: "",
    time_in_ends: "",
    time_out_starts: "",
    time_out_ends: "",
    allow_sms: "",
  });

  // const Organizer = [
  //   { label: "Andrian", value: "andrian" },
  //   { label: "Fermin", value: "fermin" },
  //   { label: "Catllia", value: "catllia" },
  // ];

  useEffect(() => {
    if (open) {
      setFormValues((prev) => ({
        ...prev,
        event_name: selectedEventValues.event_name,
        event_date: selectedEventValues.event_date,
        time_in_starts: selectedEventValues.time_in_starts,
        time_in_ends: selectedEventValues.time_in_ends,
        time_out_starts: selectedEventValues.time_out_starts,
        time_out_ends: selectedEventValues.time_out_ends,
        global_allow_sms:
          selectedEventValues.globalAllowSMS == "1" ? true : false,
        allow_sms: selectedEventValues.allow_sms == "1" ? true : false,
      }));
    }
  }, [open, selectedEventValues]);
  const handleChange = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSMSChange = (event) => {
    setFormValues(
      {
        ...formValues,
        [event.target.name]: event.target.checked,
      },
      [formValues]
    );
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .put(`${API}/event/${selectedEventValues.id}`, formValues, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          handleClose(false);
          refetch();
        }
      });
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle id="form-dialog-title">Edit Event</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContentText>Edit your event below</DialogContentText>
          <div style={{ marginBottom: 10 }}>
            <TextField
              autoFocus
              margin="dense"
              label="Event Name"
              color="primary"
              type="input"
              fullWidth
              onChange={(e) => handleChange("event_name", e.target.value)}
              value={formValues.event_name || ""}
            />
          </div>
          <div style={{ marginBottom: 15 }}>
            <TextField
              label="Event date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleChange("event_date", e.target.value)}
              value={formValues.event_date || ""}
            />
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Time"
                name="time_in_starts"
                label=" Time IN Starts"
                type="time"
                helperText="This is the time the system STARTS accepting TIME IN request."
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange("time_in_starts", e.target.value)}
                value={formValues.time_in_starts || ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Time"
                name="time_in_ends"
                label="Time IN Ends"
                type="time"
                helperText="This is the time the system STOPS accepting TIME OUT request."
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange("time_in_ends", e.target.value)}
                value={formValues.time_in_ends || ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Time"
                name="time_out_starts"
                label=" Time OUT Starts"
                type="time"
                helperText="This is the time the system STARTS accepting TIME OUT request."
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  handleChange("time_out_starts", e.target.value)
                }
                value={formValues.time_out_starts || ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Time"
                name="time_out_ends"
                label=" Time OUT Ends"
                type="time"
                helperText="This is the time the system STOPS accepting TIME OUT request."
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange("time_out_ends", e.target.value)}
                value={formValues.time_out_ends || ""}
              />
            </Grid>
          </Grid>
          {formValues.global_allow_sms && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={formValues.allow_sms}
                    onChange={handleSMSChange}
                    name="allow_sms"
                  />
                }
                label="SMS Notification"
              />
            </FormGroup>
          )}
        </DialogContent>
        {/* <Select options={Organizer} isMulti /> */}
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Update
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
